import React from "react";
import BlockTitle from "./BlockTitle";

const Services = () => {
  return (
    <section className="service-one" id="features">
      <div className="container">
        <BlockTitle
          textAlign="center"
          paraText="The simple but effective"
          titleText='Features of SignBox'
        />
        <div className="row">
          <div className="col-lg-4 col-md-12">
            <div className="service-one__single">
              <div className="service-one__inner">
                <div className="service-one__circle"></div>
                <div className="service-one__icon">
                  <i className="apton-icon-computer-graphic"></i>
                </div>
                <h3>Documents</h3>
                <p>This is where you will find all of the documents that you have signed electronically. Access them, download them. You can also see various information about each document. Like its type, its weight or with which solution you signed it</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12">
            <div className="service-one__single">
              <div className="service-one__inner">
                <div className="service-one__circle"></div>
                <div className="service-one__icon">
                  <i className="apton-icon-development"></i>
                </div>
                <h3>Dashboard</h3>
                <p>Here you will find statistics and key information on all the documents that you have signed electronically. From the number of documents signed per month, including the type of document or even the distribution according to each electronic signature solution</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12">
            <div className="service-one__single">
              <div className="service-one__inner">
                <div className="service-one__circle"></div>
                <div className="service-one__icon">
                  <i className="apton-icon-development1"></i>
                </div>
                <h3>Settings</h3>
                <p>On this page you can independently configure each electronic signature solution you use. You have the configurations by token or by authentication depending on each solution</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;

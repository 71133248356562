import React from "react";
import { Container } from "react-bootstrap";
import BlockTitle from "./BlockTitle";
import AccordionItem from "./AccordionItem";
import faqBG from "../assets/images/shapes/faq-bg-1-1.png";

const FAQ = (props) => {
  return (
    <section className="faq-one" id="benefits">
      <img src={faqBG} className="faq-one__bg-shape-1" alt="awesome post" />
      <Container>
        <BlockTitle
          textAlign="center"
          paraText="Benefits of SignBox"
          titleText={`your time is precious`}
        />
        <div className="accrodion-grp faq-accrodion">
          <AccordionItem
            title="You no longer waste time"
            content="All of your electronically signed documents can now be found in one place. You will therefore no longer waste time looking for which electronic signature solution your service provider made you sign your invoice, contract or purchase order."
            status={false}
          />
          <AccordionItem
            title="No blocking and no limit"
            content="SignBox does not offer an electronic solution but only a centralization tool. By using the SignBox solution, you do not therefore block yourself in any way in your exchanges with your service providers or customers. SignBox is only there for you, to make your life easier and save you time by centralizing all your electrically signed documents on a single interface."
            status={true}
          />
          <AccordionItem
            title="Your statistics in one place"
            content="As the SignBox solution centralizes all of your electronic signed documents from any electronic signature platform, we can offer you unprecedented data! You will now be able to see how many documents you electronically signed this month or how many of those documents were a contract or an invoice. You will also be able to have more generic information over time by monitoring the evolution of the average annual number of electronic signatures per user."
            status={false}
          />
        </div>
      </Container>
    </section>
  );
};

export default FAQ;
